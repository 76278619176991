@import-normalize;

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Medium.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: inherit;
  position: relative;
  overflow: hidden;
}

#share-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2px);
  z-index: 11000;
  display: none;
}
#share-bg.vis {
  display: block;
}

#container, #header {
 z-index: auto !important;
}
@media (min-width: 768px) {
  #container, #header {
    max-width: 1080px;
    margin: 0 auto;
  }
}

.enhancedSearchEnabled .navbar .search-box {
  display: none !important;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

@keyframes pulse-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(51, 51, 51, 0.6);
  }
  70% {
    box-shadow: 0 0 0 40px rgba(51, 51, 51, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(51, 51, 51, 0);
  }
}
